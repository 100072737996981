export default {
  init() {
    const cookieAlert = document.querySelector('#cookie-alert');
    const cookieAccept = document.querySelector('#cookie-accept');

    cookieAccept.addEventListener('click', () => {
      // ciasteczko na 30 dni
      document.cookie = 'accepted-cookies=true;  path=/; max-age=' + 60 * 60 * 24 * 30;
      cookieAlert.classList.add('hidden');
    });

    if (document.cookie.includes('accepted-cookies=true')) {
      cookieAlert.classList.add('hidden');
    } else {
      cookieAlert.classList.remove('hidden');
    }
  },
};

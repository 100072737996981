import { domReady } from '@roots/sage/client';
import Alpine from 'alpinejs';
import setCookie from './partials/cookie';

window.Alpine = Alpine;

/**
 * app.main
 */
const main = async (err) => {
  if (err) {
    // handle hmr errors
    console.error(err);
  }

  // application code
  Alpine.start();

  // cookie
  setCookie.init();
};

/**
 * Initialize
 *
 * @see https://webpack.js.org/api/hot-module-replacement
 */
Alpine.start();
domReady(main);
import.meta.webpackHot?.accept(main);
